import { createSystem, defineConfig, defineRecipe, defaultConfig, Button } from "@chakra-ui/react"

const buttonRecipe = defineRecipe({
  variants: {
    variant: {
      primary: {
        fontSize: "lg",
        px: 6,
        py: 3,
        color: `{colors.white}`,
        bg: `{colors.brandBlue}`,
        _hover: {
          bg: "whiteAlpha.200",
        },
      },
      secondary: {
        fontSize: "lg",
        px: 6,
        py: 3,
        color: "gray.800",
        bg: "white",
      },
      yellow: {
        fontSize: "lg",
        px: 6,
        py: 3,
        color: "gray.800",
        bg: `{colors.brandYellow}`,
      },
    },
  },
});

const customConfig = defineConfig({
  theme: {
    tokens: {
      fonts: {
        heading: { value: `"Archivo Black", sans-serif` },
        body: { value: `"Archivo", sans-serif` },
      },
      colors: {
        brandBlue: { value: "#2b70e4" },
        brandYellow: { value: "#ffd034" },
        brandGreen: { value: "#34a853" },
      },
    },
    recipes: {
      button: buttonRecipe,
    },
  },
  globalCss: {
    "form": {
      width: "100%",
    },
  },
})

export const system = createSystem(defaultConfig, customConfig);

import React from "react"
import { Provider } from "./src/components/ui/provider";
import "@fontsource/archivo";
import "@fontsource/archivo-black";

export const wrapRootElement = ({ element }, pluginOptions) => {
  return (
    <Provider>
        {element}
    </Provider>
  )
}
